import { LocationsAndAggregations } from '@zupr/types/next'
import React from 'react'

import PaginationListFooter from '../../../shared/components/pagination/footer'
import NoResults from '../../../shared/search/no-results'

import { useQuery } from '../../../context/route'

import Places from '../../../shared/map/places'
import LocationItem from '../../components/search/result/location'

import Filters from './filters'

import GridBlock from '../../components/grid/block'
import GridPage from '../../components/grid/page'
import SiteFull from '../../components/site-full'

import { useIsMobile } from '../../../context/ux'
import Breadcrumbs from './components/breadcrumbs'
import LocationListSearch from './components/search'
import SortAndPagination, { PaginationAndFilterButton } from './components/sort'

import { useBoundingBox } from '../../../hooks/location'
import GridColumn from '../../components/grid/column'
import ViewMap from './components/view-switch'

import GridBreadcrumbs from '../../components/grid/breadcrumbs'
import LocationClassifications from './filters/classification'

const LocationsPage = ({ locations, ...props }: LocationsAndAggregations) => {
    const isMobile = useIsMobile()
    const query = useQuery()
    useBoundingBox('box')

    return (
        <SiteFull>
            <GridPage rightWide>
                {!isMobile && (
                    <GridBreadcrumbs>
                        <Breadcrumbs {...props} />
                    </GridBreadcrumbs>
                )}
                {!isMobile && (
                    <GridColumn>
                        <GridBlock>
                            <LocationClassifications {...props} />
                        </GridBlock>
                        <GridBlock>
                            <Filters
                                baseUrl="/winkels"
                                locations={locations}
                                {...props}
                            />
                        </GridBlock>
                    </GridColumn>
                )}
                <GridBlock className="grid-list grid-list-locations">
                    {!isMobile && <LocationListSearch />}
                    {!isMobile && <SortAndPagination locations={locations} />}

                    {isMobile && <Places />}
                    {isMobile && <ViewMap mapView={false} baseUrl="/winkels" />}
                    {isMobile && (
                        <PaginationAndFilterButton
                            baseUrl="/winkels"
                            locations={locations}
                            {...props}
                        />
                    )}

                    {locations && (
                        <React.Fragment>
                            {!locations.count && (
                                <NoResults term={query.autocomplete} />
                            )}
                            {!!locations.count && (
                                <ul>
                                    {locations.results.map((location) => (
                                        <LocationItem
                                            key={location.url}
                                            {...location}
                                        />
                                    ))}
                                </ul>
                            )}
                            <PaginationListFooter count={locations.count} />
                        </React.Fragment>
                    )}
                </GridBlock>
            </GridPage>
        </SiteFull>
    )
}

export default LocationsPage
